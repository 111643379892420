<template lang="">
    <div>
        <SupportDetail/>
    </div>
</template>
<script>
export default {
    components:{
        SupportDetail: () =>import('@/components/support/SupportDetail.vue')
    }
}
</script>
<style lang="">
    
</style>